<template>
	<div
		ref="lazyImage"
		class="-mx-5 flex h-80 items-center rounded bg-cover bg-[15%_0] bg-no-repeat p-8 md:bg-center"
		:class="alignment"
		role="img"
		:alt="altText"
		:aria-label="altText"
	>
		<span class="sr-only">{{ altText }}</span>

		<slot></slot>
	</div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, onUnmounted } from 'vue';

interface Props {
	imageUrl: string;
	lazyLoad?: boolean;
	altText: string;
	slotAlignment?: 'left' | 'center' | 'right';
}

const props = withDefaults(defineProps<Props>(), {
	lazyLoad: false,
	altText: '',
	slotAlignment: 'left',
});

const lazyImage = ref<HTMLElement | null>(null);

const alignment = computed(() => {
	if (props.slotAlignment === 'left') {
		return 'justify-start';
	} else if (props.slotAlignment === 'center') {
		return 'justify-center';
	} else if (props.slotAlignment === 'right') {
		return 'justify-end';
	}

	return 'justify-start';
});

let observer: IntersectionObserver | null = null;

onMounted(() => {
	const options: IntersectionObserverInit = {
		root: null,
		rootMargin: '300px',
		threshold: 0,
	};

	function handleIntersect(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
		entries.forEach((entry) => {
			if (entry.isIntersecting && lazyImage.value) {
				lazyImage.value.style.backgroundImage = `url(${props.imageUrl})`;
				observer.unobserve(lazyImage.value);
			}
		});
	}

	observer = new IntersectionObserver(handleIntersect, options);
	if (lazyImage.value) {
		observer.observe(lazyImage.value);
	}
});

onUnmounted(() => {
	if (observer) {
		observer.disconnect();
	}
});
</script>
